import Vue from 'vue'
import {config, library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faAddressBook,
    faAlignLeft,
    faAngleLeft,
    faAngleRight,
    faAnglesLeft,
    faAnglesRight,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowRightToBracket,
    faArrowsAltH,
    faArrowUp,
    faArrowUpRightFromSquare,
    faAt,
    faBan,
    faBell,
    faBellSlash,
    faBox,
    faBuilding,
    faBullseye,
    faCalendarAlt,
    faCalendarDay,
    faChartColumn,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faCircle,
    faCircleInfo,
    faCircleMinus,
    faCircleNotch,
    faClockRotateLeft,
    faClone,
    faCloudArrowDown,
    faCloudArrowUp,
    faCog,
    faCogs,
    faComment,
    faCommentAlt,
    faCommentsDollar,
    faCopy,
    faCube,
    faCubes,
    faDownload,
    faEdit,
    faEllipsisV,
    faEnvelope,
    faEnvelopeCircleCheck,
    faEraser,
    faExclamation,
    faExclamationTriangle,
    faEye,
    faEyeSlash,
    faFileAlt,
    faFileContract,
    faFileExcel,
    faFileInvoice,
    faFilePdf,
    faFileSignature,
    faFileWord,
    faFilter,
    faFlag,
    faForward,
    faGears,
    faGlasses,
    faGroupArrowsRotate,
    faHandHolding,
    faHandshake,
    faHandshakeSlash,
    faHistory,
    faImage,
    faInfo,
    faInfoCircle,
    faKey,
    faLink,
    faLinkSlash,
    faList,
    faListAlt,
    faListCheck,
    faListUl,
    faLock,
    faLockOpen,
    faLongArrowAltLeft,
    faLongArrowAltRight,
    faMagic,
    faMagnifyingGlass,
    faMagnifyingGlassPlus,
    faMailBulk,
    faMinus,
    faMobileRetro,
    faNewspaper,
    faObjectUngroup,
    faPaperclip,
    faPaperPlane,
    faPencilAlt,
    faPeopleArrows,
    faPhone,
    faPhoneSlash,
    faPlus,
    faPlusMinus,
    faPlusSquare,
    faQuestion,
    faQuestionCircle,
    faQuoteRight,
    faRightFromBracket,
    faRightLeft,
    faRotateRight,
    faShareNodes,
    faShareSquare,
    faSignOutAlt,
    faSlash,
    faSpinner,
    faSquareArrowUpRight,
    faStar,
    faStickyNote,
    faSyncAlt,
    faTable,
    faTableColumns,
    faThLarge,
    faTimes,
    faTimesCircle,
    faTrashAlt,
    faTrashRestoreAlt,
    faUndo,
    faUpload,
    faUser,
    faUserCheck,
    faUserFriends,
    faUserMinus,
    faUserPlus,
    faUsers,
    faXmark
} from '@fortawesome/free-solid-svg-icons'

import {
    faGoogle as fabGoogle,
    faLinkedin as fabLinkedIn,
    faTwitter as fabTwitter,
    faWindows as fabWindows,
    faYahoo as fabYahoo
} from "@fortawesome/free-brands-svg-icons";

import {
    faAddressBook as farAddressBook,
    faAddressCard as farAddressCard,
    faCalendarPlus as farCalendarPlus,
    faCheckSquare as farCheckSquare,
    faCircle as farCircle,
    faClock as farClock,
    faComment as farComment,
    faCopy as farCopy,
    faCopyright as farCopyright,
    faEnvelope as farEnvelope,
    faFile as farFile,
    faFileArchive as farFileArchive,
    faFileAudio as farFileAudio,
    faFileExcel as farFileExcel,
    faFileImage as farFileImage,
    faFilePdf as farFilePdf,
    faFilePowerpoint as farFilePowerpoint,
    faFileVideo as farFileVideo,
    faFileWord as farFileWord,
    faFlag as farFlag,
    faQuestionCircle as farQuestionCircle,
    faSquare as farSquare,
    faStar as farStar,
    faStickyNote as farStickyNote,
    faUserCircle as farUserCircle,
} from "@fortawesome/free-regular-svg-icons";

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(
    // Solid Icons
    faGears,
    faChartColumn,
    faRightFromBracket,
    faMobileRetro,
    farAddressCard,
    faAddressBook,
    faAlignLeft,
    faAngleLeft,
    faAngleRight,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowsAltH,
    faAt,
    faBan,
    faBell,
    faBellSlash,
    faBox,
    faBuilding,
    faCalendarAlt,
    faCalendarDay,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faCircle,
    faCircleInfo,
    faCircleMinus,
    faCircleNotch,
    faCog,
    faCogs,
    faComment,
    faCommentAlt,
    faCubes,
    faDownload,
    faEdit,
    faEllipsisV,
    faEnvelope,
    faEraser,
    faExclamation,
    faExclamationTriangle,
    faEye,
    faEyeSlash,
    faFileAlt,
    faFileExcel,
    faFileInvoice,
    faFilePdf,
    faFileWord,
    faFilter,
    faForward,
    faHandshake,
    faHandshakeSlash,
    faHistory,
    faImage,
    faInfo,
    faInfoCircle,
    faKey,
    faList,
    faListCheck,
    faListAlt,
    faListUl,
    faLock,
    faLockOpen,
    faLongArrowAltLeft,
    faLongArrowAltRight,
    faMagic,
    faMagnifyingGlassPlus,
    faMailBulk,
    faMinus,
    faNewspaper,
    faObjectUngroup,
    faPaperclip,
    faPencilAlt,
    faPhoneSlash,
    faPlus,
    faPlusMinus,
    faPlusSquare,
    faQuestionCircle,
    faQuestion,
    faRightLeft,
    faRotateRight,
    faShareNodes,
    faSquareArrowUpRight,
    faShareSquare,
    faSignOutAlt,
    faSlash,
    faSpinner,
    faStar,
    faFlag,
    faStickyNote,
    faSyncAlt,
    faTable,
    faThLarge,
    faTimes,
    faTimesCircle,
    faTrashAlt,
    faTrashRestoreAlt,
    faUndo,
    faUpload,
    faUser,
    faUserCheck,
    faUserFriends,
    faUserMinus,
    faUserPlus,
    faUsers,
    faQuoteRight,
    faMagnifyingGlass,
    faGroupArrowsRotate,
    faXmark,
    faFileSignature,
    faClockRotateLeft,
    faEnvelopeCircleCheck,
    faGlasses,
    faClone,
    faBullseye,
    faCopy,
    faCloudArrowDown,
    faCloudArrowUp,
    faLink,
    faFileContract,
    faPhone,
    faCube,
    faCommentsDollar,
    faArrowUpRightFromSquare,
    faPeopleArrows,
    faAnglesLeft,
    faAnglesRight,
    faTableColumns,
    faPaperPlane,
    faHandHolding,
    faArrowRightToBracket,
    faLinkSlash,
    faListCheck,

    // Brand Icons
    fabGoogle,
    fabYahoo,
    fabLinkedIn,
    fabTwitter,
    fabWindows,

    // Regular Icons
    farAddressBook,
    farAddressCard,
    farCalendarPlus,
    farCheckSquare,
    farCircle,
    farCopy,
    farCopyright,
    farEnvelope,
    farFile,
    farFileArchive,
    farFileAudio,
    farFileExcel,
    farFileImage,
    farFilePdf,
    farFilePowerpoint,
    farFileVideo,
    farFileWord,
    farQuestionCircle,
    farSquare,
    farStar,
    farFlag,
    farStickyNote,
    farUserCircle,
    farComment,
    farClock,
);

// Register the component globally
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
